import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CTResultScore,
  CommonBG,
  Mission,
  Overlay,
  MainButton as _Button,
  OverlayContent as _OverlayContent,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useEventResult } from '../../../../api/event';
import { useStatDocData } from '../../../../api/game';
import {
  useStage2InfoSubmission,
  useStage2Submission,
} from '../../../../api/stage2';
import {
  Stage2Clue,
  clues1,
  clues2,
  clues3,
  lastClueItems,
  section1,
  section2,
  section3,
} from '../../../../lib/Stage2Options';
import { calcRankMessage } from '../../../../lib/stageResults';
import { useCurrentUser } from '../../../../redux/selectors/authSelectors';
import { useEventId } from '../../../../redux/selectors/gameSelectors';
import StageTitle from '../../../../static/png/stage2/Stage2_title.png';
import { STAGE_TIME_LIMIT } from '../../stageProviders/StageManager';
import { ClueIcon, ClueState } from './Stage2Answer';
import Stage2Question from './Stage2Question';

//import { stages } from '../../../lib/Problem';

interface ResultFinalProps {
  onBack?: () => void;
}

const Stage2ResultFinal: React.FC<ResultFinalProps> = props => {
  const eventId = useEventId();
  const user = useCurrentUser();

  const [showOverlay, setShowOverlay] = React.useState(false);

  const [previousInfoSubmissions] = useStage2InfoSubmission(user, eventId);

  const [stage2subs] = useStage2Submission(user, eventId);

  const [eventResult] = useEventResult(user?.uid ?? 'userId', eventId);

  const problems = [
    {
      title: section1.title,
      clues: clues1,
    },
    {
      title: section2.title,
      clues: clues2,
    },
    {
      title: section3.title,
      clues: clues3,
    },
  ];

  // 最終問題が5点+ボーナス5点
  const maxScore =
    problems.reduce((prev, curr) => {
      return prev + curr.clues.length * 2;
    }, 0) +
    5 +
    5;

  const navigate = useNavigate();
  const goTo = (url: string) => {
    navigate(url);
  };

  const [stat, loadStat, statError] = useStatDocData(eventId);
  const rates = React.useMemo(
    () => stat?.rate.map(e => (e ?? 0) * 100) ?? [],
    [stat]
  );
  //

  const fixAnswer = React.useMemo(() => {
    if (previousInfoSubmissions == null) return [];

    return previousInfoSubmissions.map(single => {
      const _correct =
        single.answers.filter(single => single.correct == true).length > 0;

      const _answered = single.answers.length > 0;

      let _lastAnswer: string | null = null;
      if (_answered) {
        _lastAnswer = single.answers[single.answers.length - 1].answer[0];
      }
      const rate = rates?.[single.infoId - 1] ?? null;

      return {
        infoId: single.infoId,
        state: (_correct ? 'correct' : 'incorrect') as ClueState,
        lastAnswer: _lastAnswer,
        wrongCount: Math.max(
          single.answers.filter(single => single.correct == false).length,
          0
        ),
        rate: rate,
      };
    });
  }, [previousInfoSubmissions, rates]);

  const score = React.useMemo(() => {
    const ans = fixAnswer.reduce((prev, curr) => {
      if (curr.state == 'correct') {
        if (curr.wrongCount == 0) {
          return prev + 2;
        }
        if (curr.wrongCount == 1) {
          return prev + 1;
        }
      }
      return prev;
    }, 0);

    const final = stage2subs == null ? 0 : stage2subs.correct ? 5 : 0;

    let bonus = 0;
    if (stage2subs != null) {
      const startTime = eventResult?.startTimes2?.seconds;
      const finishTime = stage2subs.createdOn.seconds;

      if (startTime != null) {
        // +0-5点
        // 残り時間秒数/60の整数部分
        bonus = Math.min(
          5,
          Math.floor((STAGE_TIME_LIMIT - (finishTime - startTime)) / 60)
        );
      }
    }
    return ans + final + bonus;
  }, [fixAnswer, stage2subs, eventResult]);

  // sample /////////////////////
  const rank = React.useMemo(() => calcRankMessage(score, 1), [score]);
  const average = React.useMemo(() => {
    return stat?.averageScore ?? 0;
  }, [stat]);

  const AnswerList = ({ clues }: { clues: Stage2Clue[] }) => {
    return (
      <ListWrapper>
        <ListHeader>
          <p>問題</p>
          <p>解答</p>
          <p>誤答回数</p>
          <p>正答率</p>
        </ListHeader>
        {clues.map(cl => {
          const _ans = fixAnswer.filter(single => single.infoId == cl.serverId); // wip
          const ans = _ans.length == 0 ? null : _ans[0];
          const wrongCount = ans?.wrongCount ?? 0;

          return (
            <SingleList key={cl.id}>
              <div>
                {cl.items.map(single => {
                  if (single.type == 'text') {
                    return <p key={`${single.id}`}>{single.content}</p>;
                  }
                  return <QuestionLine key={`${single.id}`} />;
                })}
              </div>
              <div>
                {ans == null ? (
                  <p style={{ opacity: 0.2 }}>(未解答)</p>
                ) : (
                  <>
                    <ClueIcon state={ans.state} />
                    <>{ans.lastAnswer}</>
                  </>
                )}
              </div>
              <div
                style={{
                  opacity: wrongCount > 0 ? 1.0 : 0.2,
                  color: wrongCount > 0 ? 'red' : 'black',
                }}
              >
                {wrongCount}
              </div>
              <Rate>
                {ans != null && <>{ans.rate != null && <>{ans.rate}%</>}</>}
              </Rate>
            </SingleList>
          );
        })}
      </ListWrapper>
    );
  };

  return (
    <CommonBG
      withHeader
      withFooter
      headerOptions={{ buttonType: 'logout', logoType: 'CT' }}
    >
      <Overlay
        isVisible={showOverlay}
        onClick={() => {
          setShowOverlay(false);
        }}
      >
        {showOverlay && (
          <OverlayContent>
            <QuestionWrapper>
              <Stage2Question />
            </QuestionWrapper>
          </OverlayContent>
        )}
      </Overlay>
      <Rule>
        <Title src={StageTitle} />
        <Mission>
          資料をもとに
          <br />
          事件の犯人を推理しなさい。
        </Mission>
        <Time>
          <FontAwesomeIcon icon={faStopwatch} />
          制限時間 6分
        </Time>
        <VideoWrapper>
          <video controls>
            <source
              src={
                'https://storage.googleapis.com/specc-ct-movie/1-1Switch_%E3%83%AB%E3%83%BC%E3%83%AB%E8%AA%AC%E6%98%8E.mp4'
              }
              type='video/mp4'
            />
            <p>Your browser doesn't support HTML5 video.</p>
          </video>
        </VideoWrapper>
        <CTResultScore
          score={score}
          maxScore={maxScore}
          rank={rank}
          average={average}
        />
        <DocumentButtonWrapper>
          <Button
            color='positive'
            size='large'
            onClick={() => {
              setShowOverlay(true);
            }}
          >
            証言の資料を見る
          </Button>
        </DocumentButtonWrapper>
        {stage2subs != null && (
          <Description>
            <h2>あなたの解答</h2>

            {problems.map((single, i) => {
              return (
                <div key={`problem-${i}`}>
                  <H3>{single.title}</H3>
                  <AnswerList clues={single.clues} />
                </div>
              );
            })}

            <H3>{lastClueItems[0].content}</H3>

            <ListWrapper>
              <ListHeader>
                <p>問題</p>
                <p>解答</p>
                <p></p>
              </ListHeader>
              <SingleList>
                <div>
                  <p>{lastClueItems[0].content}</p>
                  <QuestionLine />
                </div>

                <div>
                  {stage2subs ? (
                    <>
                      <ClueIcon
                        state={stage2subs.correct ? 'correct' : 'incorrect'}
                      />
                      <p>{stage2subs.finalAnswer}</p>
                    </>
                  ) : (
                    <p style={{ opacity: 0.2 }}>(未解答)</p>
                  )}
                </div>
                <div></div>
              </SingleList>
            </ListWrapper>
          </Description>
        )}
        <Buttons>
          <Button
            color='negative'
            size='large'
            onClick={() => {
              const historyUrl =
                'https://specc-dev.riddler.co.jp/history/' + eventId;
              location.href = historyUrl;
            }}
          >
            戻る
          </Button>
        </Buttons>
      </Rule>
    </CommonBG>
  );
};

const Rate = styled.div`
  font-weight: bold;
`;

const H3 = styled.h3`
  font-size: 2.8rem;
  margin: 20px 0;
`;

const OverlayContent = styled(_OverlayContent)`
  img {
    width: 600px !important;
  }

  p {
    width: 600px !important;
  }

  b {
    color: white;
    font-size: 2rem;
    font-weight: bold;
    background: #d53220;
    padding: 2px 20px;
    margin-right: 10px;
  }
`;

const QuestionLine = styled.div`
  width: 60px;
  height: 20px;
  margin: 0 6px;
  border-bottom: 1px solid #343a40;
`;

const ListWrapper = styled.div`
  border: 1px solid #ced4da;
  overflow: hidden;
  transition: 0.2s;
`;

const ListHeader = styled.div`
  display: flex;
  background: #f3f4f6;
  font-weight: bold;
  font-size: 1.8rem;
  padding: 12px;

  >p: nth-child(1) {
    flex: 1;
    padding-left: 20px;
  }
  >p: nth-child(2) {
    flex: 0.3;
  }
  >p: nth-child(3) {
    flex: 0.2;
  }
  >p: nth-child(4) {
    flex: 0.2;
  }
`;

const SingleList = styled.div`
  display: flex;
  position: relative;
  font-weight: bold;

  &:nth-child(2n) {
    background: #ffffff;
  }

  &:nth-child(2n-1) {
    background: #f3f4f6;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    padding: 14px 0px;
    align-items: center;
  }

  >div: nth-child(1) {
    flex: 1;
    white-space: nowrap;
    padding-left: 20px;
  }
  >div: nth-child(2) {
    white-space: nowrap;
    flex: 0.3;
  }
  >div: nth-child(3) {
    flex: 0.2;
  }
  >div: nth-child(4) {
    flex: 0.2;
  }
`;

const ResultHeader = styled.div`
  border-bottom: 4px solid #189bf2;
  margin: 0 3rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  img {
    margin: 2.4rem 0;
    width: 212px;
    height: 32px;
  }
`;

const Time = styled.div`
  background: rgba(206, 212, 218, 0.25);
  font-size: 1.8rem;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 4px 12px;
  margin-top: -1rem;

  svg {
    margin-right: 4px;
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  margin: 1rem auto;
  border: solid 2px #6c7680;

  video {
    width: 100%;
    margin-bottom: -4px;
  }
`;

const Title = styled.img`
  display: block;
  height: 80px;
  width: auto;
  margin: 4rem auto 0;
`;

const Button = styled(_Button).attrs({
  variant: 'main',
})`
  max-width: 47%;
  margin: 0 1rem;

  &:last-child {
    margin-right: 0;
  }
`;

const DocumentButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 4rem auto;
`;

const Buttons = styled.div`
  height: 6rem;
  display: flex;
  justify-content: center;
  margin: 0 auto 10rem;
  margin-top: 2rem;
`;

const Rule = styled.div`
  max-width: 800px;
  margin: 6rem auto;
  margin-top: 0;
  display: block;
`;

const Description = styled.div`
  padding: 2rem;
  font-size: 1.6rem;

  ul {
    margin-left: 2rem;
  }

  li {
    margin-bottom: 1.4rem;
  }

  b {
    border-bottom: 1px solid black;
  }

  h2 {
    font-size: 2.8rem;
    margin-bottom: 1rem;
  }
`;

const QuestionWrapper = styled.div`
  max-height: calc(100vh - 80px);
  overflow-y: scroll;
`;

export default Stage2ResultFinal;

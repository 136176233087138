import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useSubmitAnswer2Mutation } from '../../../../api/__generated__/stage2.generated';
import { finishGame } from '../../../../api/game';
import { useStage2Submission } from '../../../../api/stage2';
import useLocalStorage from '../../../../lib/useLocalStorage';
import { useRetryableMutationWithUI } from '../../../../lib/useRetryableMutationWithUI';
import { gameActions } from '../../../../redux/actions/gameActions';
import { useCurrentUser } from '../../../../redux/selectors/authSelectors';
import { useEventId } from '../../../../redux/selectors/gameSelectors';
import { useRemainingTime } from '../../../../redux/selectors/timeSelectors';
import CommonBG from '../../../uiElements/CommonBG';
import TimeGauge from '../../../uiElements/timeGauge/TimeGauge';
import StageManager from '../../stageProviders/StageManager';
import Stage2Answer from './Stage2Answer';
import Stage2LastAnsSent from './Stage2LastAnsSent';
import Stage2Question from './Stage2Question';
import Stage2Result from './Stage2Result';
import Stage2ResultPage from './Stage2ResultPage';

interface Stage2Props {}

const Stage2: React.FC<Stage2Props> = () => {
  const dispatch = useDispatch();
  const remainingTime = useRemainingTime();
  const eventId = useEventId();
  const user = useCurrentUser();
  const [submitAnswer2, submitAnswer2Result] = useRetryableMutationWithUI(
    useSubmitAnswer2Mutation,
    {
      hookOptions: {},
      loading: {
        options: {
          text: '送信中...',
        },
      },
    }
  );

  const [stage2Status, setStage2Status] = useLocalStorage<string | null>(
    'stage2:' + eventId + ':' + user?.uid,
    null
  );
  const [sentLastAns, setSentLastAns] = React.useState<boolean>(false);
  const [isLastAnsCorrect, setIsLastAnsCorrect] =
    React.useState<boolean>(false);
  const [showingResult, setShowingResult] = React.useState<boolean>(false);
  const [showingResultPage, setShowingResultPage] =
    React.useState<boolean>(false);
  const [previousLastAnsSubmission] = useStage2Submission(user, eventId);

  React.useEffect(() => {
    if (!isLastAnsCorrect && stage2Status === 'lastAnswer:correct') {
      setIsLastAnsCorrect(true);
    }
  }, [isLastAnsCorrect, stage2Status]);
  React.useEffect(() => {
    if (previousLastAnsSubmission === undefined) {
      setSentLastAns(false);
      return;
    }
    setSentLastAns(true);
    setIsLastAnsCorrect(previousLastAnsSubmission.correct === true);
    setStage2Status(prev => {
      if (prev !== 'finished') {
        return (
          'lastAnswer:' +
          (previousLastAnsSubmission.correct === true ? 'correct' : 'wrong')
        );
      }
      return prev;
    });
    setShowingResult(true);
  }, [previousLastAnsSubmission, setStage2Status]);

  const onSubmitLastAnswer = useCallback(
    (answer: string) => {
      if (eventId === null) {
        return;
      }

      submitAnswer2({
        variables: {
          input: {
            eventId: eventId,
            answer,
          },
        },
      });
    },
    [eventId, submitAnswer2]
  );

  const onConfirmResult = React.useCallback(() => {
    setShowingResult(false);
    setShowingResultPage(true);
  }, []);

  const onComplete = React.useCallback(() => {
    if (eventId != null && user?.uid != null) {
      finishGame(eventId, user.uid);
    }
    dispatch(gameActions.setHasStageFinished({ stage: 2, finished: true }));
    setStage2Status('finished');
  }, [dispatch, setStage2Status]);

  React.useEffect(() => {
    const res = submitAnswer2Result;
    if (!res.called || res.loading) {
      return;
    }

    setSentLastAns(true);
    setIsLastAnsCorrect(res.data?.submitAnswer2?.correct === true);
    setStage2Status(prev => {
      if (prev !== 'finished') {
        return (
          'lastAnswer:' +
          (res.data?.submitAnswer2?.correct === true ? 'correct' : 'wrong')
        );
      }
      return prev;
    });
  }, [submitAnswer2Result, dispatch, setStage2Status]);

  React.useEffect(() => {
    if (remainingTime === 0) {
      if (sentLastAns) {
        setShowingResult(true);
      } else {
        setIsLastAnsCorrect(false);
        setShowingResult(true);
      }
    }
  }, [onComplete, onConfirmResult, remainingTime, sentLastAns]);

  return (
    <StageManager stageId={2}>
      <CommonBG>
        {showingResultPage ? (
          <Stage2Result
            isCorrect={stage2Status === 'correct'}
            onComplete={onComplete}
          />
        ) : showingResult ? (
          <Stage2Result isCorrect={isLastAnsCorrect} onComplete={onComplete} />
        ) : (
          <>
            <TimeGauge />
            <MainWrapper>
              <Stage2Question />
              <Stage2Answer onSubmitLastAnswer={onSubmitLastAnswer} />
            </MainWrapper>
          </>
        )}
      </CommonBG>
    </StageManager>
  );
};

const MainWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 3rem);
  margin: 3rem auto 0;
  display: flex;
  overflow: hidden;
`;

export default Stage2;

import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { bareClient, getClient } from './api/client';
import { useServerTimeOffsetEffect } from './api/common';
import DevEnvIndicator from './components/dev/DevEnvIndicator';
import AuthErrorPage from './components/eventProvider/common/AuthErrorPage';
import RedirectPage from './components/eventProvider/common/RedirectPage';
import EventsRouter from './components/eventProvider/EventsRouter';
import AuthStateListener from './components/global/AuthStateListener';
import ErrorConsole from './components/global/ErrorConsole';
import ErrorOverlayController from './components/global/overlays/error/ErrorOverlayController';
import LoadingOverlay from './components/global/overlays/loading/LoadingOverlay';
import PanicConsole from './components/global/PanicConsole';
import {
  useCurrentUser,
  useIsUserInitialized,
} from './redux/selectors/authSelectors';
import Store from './redux/store';
import { app } from '.';

export default function App() {
  const [client, setClient] =
    React.useState<ApolloClient<NormalizedCacheObject>>(bareClient);

  const updateApolloClient = () => {
    getClient().then(client => setClient(client));
  };
  React.useEffect(() => {
    updateApolloClient();

    onAuthStateChanged(getAuth(app), () => {
      updateApolloClient();
    });
  }, []);

  return (
    <ApolloProvider client={client}>
      <Provider store={Store}>
        <ErrorOverlayController />
        <LoadingOverlay />
        <AuthStateListener />
        <Router />
      </Provider>
    </ApolloProvider>
  );
}

interface ConditionedRouteRule {
  condition: boolean;
  redirectPathOnFail: string;
}
interface ConditionedRouteProps {
  rules: ConditionedRouteRule[];
}

const ConditionedRoute: React.FC<ConditionedRouteProps> = props => {
  /*
  for (const rule of props.rules) {
    if (!rule.condition) {
      return <Navigate to={rule.redirectPathOnFail} />;
    }
  }*/
  return <>{props.children}</>;
};

interface RouterProps {}
const Router: React.FC<RouterProps> = () => {
  const user = useCurrentUser();
  const isUserInitialized = useIsUserInitialized();
  useServerTimeOffsetEffect();

  if (!isUserInitialized) {
    return null;
  }

  return (
    <BrowserRouter>
      <PanicConsole />
      <ErrorConsole />
      <DevEnvIndicator />
      <Routes>
        <Route path='/auth-error' element={<AuthErrorPage />} />
        <Route path='/events/*' element={<EventsRouter />} />
        <Route path='/' element={<RedirectPage />} />
        <Route path='/*' element={<Navigate to='/' />} />
      </Routes>
    </BrowserRouter>
  );
};

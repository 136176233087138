import * as React from 'react';
import styled from 'styled-components';

import Stage2QuestionImg1 from '../../../../static/png/stage2/stage2_documents_1.jpg';
import Stage2QuestionImg2 from '../../../../static/png/stage2/stage2_documents_2.jpg';
import Stage2QuestionImg3 from '../../../../static/png/stage2/stage2_documents_3.jpg';
import Stage2QuestionImg4 from '../../../../static/png/stage2/stage2_documents_4.jpg';

interface Stage2QuestionProps {}

const Stage2Question: React.FC<Stage2QuestionProps> = () => {
  return (
    <Stage2QuestionWrapper>
      <Question src={Stage2QuestionImg1} />
      <Question src={Stage2QuestionImg2} />
      <Question src={Stage2QuestionImg3} />
      <Question src={Stage2QuestionImg4} />
    </Stage2QuestionWrapper>
  );
};

const Question = styled.img`
  width: 100%;
  display: block;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 8rem;
  }
`;

const Stage2QuestionWrapper = styled.div`
  background: rgba(200, 200, 200, 0.7);
  height: 80rem;
  height: 100%;
  padding: 4rem;
  overflow-y: scroll;
  flex: 4;
`;

export default Stage2Question;

import { gql } from '@apollo/client';
import { User } from 'firebase/auth';
import {
  CollectionReference,
  DocumentReference,
  collection,
  doc,
} from 'firebase/firestore';
import {
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore';
import { LoadingHook } from 'react-firebase-hooks/firestore/dist/util';

import { Game2, Game2Info } from './firestoreTypes';
import { db } from '..';

export const stage2Schema = gql`
  mutation SubmitAnswer2($input: Answer2!) {
    submitAnswer2(input: $input) {
      correct
    }
  }

  mutation submitAnswer2Info($input: InfoInput!) {
    submitAnswer2Info(input: $input)
  }
`;

export type Stage2InfoSubmission = Game2Info;
export type Stage2Submission = Game2;

export const useStage2Submission = (
  user: User | null,
  eventId: string | null
): LoadingHook<Stage2Submission, Error> => {
  return useDocumentData<Stage2Submission>(
    /*user !== null &&  && */ eventId !== null
      ? (doc(
          db,
          'version/1/submissions',
          eventId,
          user?.uid ?? 'userId',
          '2'
        ) as DocumentReference<Stage2Submission>)
      : undefined
  );
};
export const useStage2SubmissionByUID = (
  uid: string | null,
  eventId: string | null
): LoadingHook<Stage2Submission, Error> => {
  return useDocumentData<Stage2Submission>(
    uid !== null && eventId !== null
      ? (doc(
          db,
          'version/1/submissions',
          eventId,
          uid,
          '2'
        ) as DocumentReference<Stage2Submission>)
      : undefined
  );
};

// Overview用
export const useStage2InfoSubmissionByUID = (
  uid: string | null,
  eventId: string | null
): LoadingHook<Stage2InfoSubmission, Error> => {
  return useDocumentData<Stage2InfoSubmission>(
    uid !== null && eventId !== null
      ? (doc(
          db,
          'version/1/submissions',
          eventId,
          uid,
          '2Info'
        ) as DocumentReference<Stage2InfoSubmission>)
      : undefined
  );
};

export const useStage2InfoSubmission = (
  user: User | null,
  eventId: string | null
): LoadingHook<Stage2InfoSubmission[], Error> => {
  return useCollectionData<Stage2InfoSubmission>(
    /*user !== null && */ eventId !== null
      ? (collection(
          db,
          'version/1/submissions',
          eventId,
          user?.uid ?? 'userId',
          '2Info',
          'problem_id'
        ) as CollectionReference<Stage2InfoSubmission>)
      : undefined
  );
};

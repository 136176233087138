import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Mission,
  MainButton as _Button,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import Stage2Title from '../../../static/png/stage2/Stage2_title.png';
import CommonBG from '../../uiElements/CommonBG';

interface RuleInstrctionProps {
  stageId: number;
  stageTitleImgSrc?: string;
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
}

const RuleInstrction: React.FC<RuleInstrctionProps> = props => {
  return (
    <CommonBG>
      <Rule>
        <Title src={Stage2Title} />
        <Mission>
          資料をもとに
          <br />
          事件の犯人を推理しなさい。
        </Mission>
        <Time>
          <FontAwesomeIcon icon={faStopwatch} />
          制限時間 6分
        </Time>
        <VideoWrapper>
          <video controls>
            <source
              src={
                'https://storage.googleapis.com/specc-ct-movie/1-1Switch_%E3%83%AB%E3%83%BC%E3%83%AB%E8%AA%AC%E6%98%8E.mp4'
              }
              type='video/mp4'
            />
            <p>Your browser doesn't support HTML5 video.</p>
          </video>
        </VideoWrapper>
        <Description>
          <h2>ルール</h2>
          <ul>
            <li>
              手元の資料から情報を収集し、問題の空欄を埋めてください。
              <br />
              （問題は全て選択式です）
            </li>
            <li>
              全ての問題に正解すると、犯人を答える最終問題に挑戦できます。
              <b>最終問題の解答は一度しか行えません。</b>
            </li>
            <li>
              正解数に応じて得点が与えられます。ただし、
              <b>不正解を繰り返すと得点が減点されます。</b>
            </li>
          </ul>
        </Description>

        <Buttons>
          <Button
            color='negative'
            size='large'
            onClick={() => props.onBack?.()}
          >
            戻る
          </Button>
          <Button
            color='positive'
            size='large'
            onClick={() => props.onStart?.()}
          >
            スタート
          </Button>
        </Buttons>
      </Rule>
    </CommonBG>
  );
};

const RuleThumb = styled.img`
  width: auto;
  height: 240px;
  display: block;
  margin: 10px auto;
`;

const Time = styled.div`
  background: rgba(206, 212, 218, 0.25);
  font-size: 1.8rem;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 4px 12px;
  margin-top: -1rem;

  svg {
    margin-right: 4px;
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  margin: 1rem auto;
  border: solid 2px #6c7680;

  video {
    width: 100%;
    margin-bottom: -4px;
  }
`;

const Title = styled.img`
  display: block;
  height: 80px;
  width: auto;
  margin: 4rem auto 0;
`;

const Button = styled(_Button).attrs({
  variant: 'main',
})`
  max-width: 47%;
  margin: 0 1rem;

  &:last-child {
    margin-right: 0;
  }
`;

const Buttons = styled.div`
  height: 6rem;
  display: flex;
  justify-content: center;
  margin: 0 auto 10rem;
  margin-top: 2rem;
`;

const Rule = styled.div`
  max-width: 800px;
  margin: 6rem auto;
  margin-top: 0;
  display: block;
`;

const Description = styled.div`
  padding: 2rem;
  font-size: 1.6rem;

  ul {
    margin-left: 2rem;
  }

  li {
    margin-bottom: 1.4rem;
  }

  b {
    border-bottom: 1px solid black;
  }

  h2 {
    font-size: 2.8rem;
    margin-bottom: 1rem;
  }
`;

export default RuleInstrction;

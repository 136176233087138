import { useCTSignIn } from '@riddler-co-jp/specc-auth-client';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';

import { useEventAvailable } from '../../api/event';
import { useStartTimesEffect } from '../../api/stages';
import { appActions } from '../../redux/actions/appActions';
import { useCurrentUser } from '../../redux/selectors/authSelectors';
import { app } from '../..';
import Loading from './common/Loading';
import Stage2 from './stagePages/stage2/Stage2';
import Stage2ResultFinal from './stagePages/stage2/Stage2ResultFinal';

interface EventRouterProps {}

export const EventsRouter: React.FC<EventRouterProps> = () => {
  const [eventAvailable, eventLoading] = useEventAvailable();
  const match = useMatch('/events/:eventId/*');
  useStartTimesEffect();
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (eventLoading) {
      dispatch(appActions.setLoadingState({ visible: true }));
    } else {
      dispatch(appActions.setLoadingState({ visible: false }));
    }
  }, [dispatch, eventLoading]);

  const [signedIn, signInLoading] = useCTSignIn(
    app,
    'program',
    match?.params.eventId,
    '2',
    match?.pathname === match?.pathnameBase ||
      match?.pathname === `${match?.pathnameBase}/`
  );

  if (signInLoading) {
    return <Loading />;
  }
  if (!signInLoading && !signedIn) {
    return <Navigate to='/auth-error' />;
  }

  return (
    <Routes>
      <Route path='/:eventId' element={<Stage2 />} />
      <Route path='/:eventId/*' element={<Stage2 />} />
      <Route path='/:eventId/result/program' element={<Stage2ResultFinal />} />
    </Routes>
  );
};

export default EventsRouter;

import { OptionTypeBase, OptionsType } from 'react-select';

type Options = OptionsType<OptionTypeBase>;

interface Stage2ClueTextItem {
  type: 'text';
  id: string;
  content: string;
}

interface Stage2ClueSelectItem {
  type: 'select';
  id: string;
  // serverId: string;
  content: Options;
}

interface Stage2ClueInputItem {
  type: 'input';
  // serverId: string;
  id: string;
}

type Stage2ClueItem =
  | Stage2ClueTextItem
  | Stage2ClueSelectItem
  | Stage2ClueInputItem;

export interface Stage2Clue {
  id: string;
  items: Stage2ClueItem[];
  serverId: number;
}

interface Stage2Section {
  title: string;
  subTitle: string;
  clues: Stage2Clue[];
}

const nameOptions = ['一瀬', '二宮', '三田', '四葉', '五味', '六井'].map(n => ({
  value: n,
  label: n,
}));

const nameOptions1 = ['一瀬', '三田'].map(n => ({
  value: n,
  label: n,
}));

const nameOptions2 = ['一瀬', '五味'].map(n => ({
  value: n,
  label: n,
}));

const nameOptions3 = ['三田', '四葉'].map(n => ({
  value: n,
  label: n,
}));

const nameOptions4 = ['五味', '六井'].map(n => ({
  value: n,
  label: n,
}));

const colorOptions = ['赤', '黄', '緑', '青', '黒', '白'].map(n => ({
  value: n,
  label: n,
}));

const countOptions = ['0', '1', '2'].map(n => ({
  value: n,
  label: n,
}));

export const clues1: Stage2Clue[] = [
  {
    id: 'clue1-l1',
    serverId: 1,
    items: [
      {
        type: 'text',
        content: '一瀬と三田では',
        id: 'clue1-l1-1',
      },
      {
        type: 'select',
        content: nameOptions1,
        id: 'clue1-l1-2',
      },
      {
        type: 'text',
        content: 'の方が先に乗った。',
        id: 'clue1-l1-3',
      },
    ],
  },
  {
    id: 'clue1-l2',
    serverId: 2,
    items: [
      {
        type: 'text',
        content: '一瀬と五味では',
        id: 'clue1-l2-1',
      },
      {
        type: 'select',
        content: nameOptions2,
        id: 'clue1-l2-2',
      },
      {
        type: 'text',
        content: 'の方が先に乗った。',
        id: 'clue1-l2-3',
      },
    ],
  },
  {
    id: 'clue1-l3',
    serverId: 3,
    items: [
      {
        type: 'text',
        content: '三田と四葉では',
        id: 'clue1-l3-1',
      },
      {
        type: 'select',
        content: nameOptions3,
        id: 'clue1-l3-2',
      },
      {
        type: 'text',
        content: 'の方が先に乗った。',
        id: 'clue1-l3-3',
      },
    ],
  },
  {
    id: 'clue1-l4',
    serverId: 4,
    items: [
      {
        type: 'text',
        content: '五味と六井では',
        id: 'clue1-l4-1',
      },
      {
        type: 'select',
        content: nameOptions4,
        id: 'clue1-l4-2',
      },
      {
        type: 'text',
        content: 'の方が先に乗った。',
        id: 'clue1-l4-3',
      },
    ],
  },
];

export const clues2: Stage2Clue[] = [
  {
    id: 'clue2-l1',
    serverId: 5,

    items: [
      {
        type: 'text',
        content: '二宮が乗っているのは',
        id: 'clue2-l1-1',
      },
      {
        type: 'select',
        content: colorOptions,
        id: 'clue2-l1-2',
      },
    ],
  },

  {
    id: 'clue2-l2',
    serverId: 6,

    items: [
      {
        type: 'text',
        content: '四葉が乗っているのは',
        id: 'clue2-l2-1',
      },
      {
        type: 'select',
        content: colorOptions,
        id: 'clue2-l2-2',
      },
    ],
  },

  {
    id: 'clue2-l3',
    serverId: 7,

    items: [
      {
        type: 'text',
        content: '五味が乗っているのは',
        id: 'clue2-l3-1',
      },
      {
        type: 'select',
        content: colorOptions,
        id: 'clue2-l3-2',
      },
    ],
  },
];

export const clues3: Stage2Clue[] = [
  {
    id: 'clue3-l1',
    serverId: 8,

    items: [
      {
        type: 'text',
        content: '誰も乗っていないのは',
        id: 'clue3-l1-1',
      },
      {
        type: 'select',
        content: colorOptions,
        id: 'clue3-l1-2',
      },
    ],
  },

  {
    id: 'clue3-l2',
    serverId: 9,

    items: [
      {
        type: 'text',
        content: '2人乗っているのは',
        id: 'clue3-l2-1',
      },
      {
        type: 'select',
        content: colorOptions,
        id: 'clue3-l2-2',
      },
    ],
  },

  {
    id: 'clue3-l3',
    serverId: 10,

    items: [
      {
        type: 'text',
        content: '緑には12時の時点で',
        id: 'clue3-l3-1',
      },
      {
        type: 'select',
        content: countOptions,
        id: 'clue3-l3-2',
      },
      {
        type: 'text',
        content: '人乗っている。',
        id: 'clue3-l3-3',
      },
    ],
  },
];

export const section1: Stage2Section = {
  title: '1. ゴンドラに乗った順番について',
  subTitle: '',
  clues: clues1,
};

export const section2: Stage2Section = {
  title: '2. 乗ったゴンドラの色について',
  subTitle: '',
  clues: clues2,
};

export const section3: Stage2Section = {
  title: '3. ゴンドラの中の人数について',
  subTitle: '',
  clues: clues3,
};

export const lastClueItems: [Stage2ClueTextItem, Stage2ClueSelectItem] = [
  {
    type: 'text',
    content: '宝石を盗んだ犯人は',
    id: 'last-1',
  },
  {
    type: 'select',
    content: nameOptions,
    id: 'lasst-2',
  },
];

export const allSections = [section1, section2, section3];

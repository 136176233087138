import { SubButton as Button } from '@riddler-co-jp/specc-ui-components';
import { TextWindow } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import Colors from '../../../../styles/colors';

interface Stage2LastConfirmProps {
  answer: string;
  onSubmit: () => void;
  onCancel: () => void;
}

const Stage2LastConfirm: React.FC<Stage2LastConfirmProps> = props => {
  return (
    <Stage2LastConfirmWrapper>
      <Text bracket dark>
        <p>一度しか送信できません。</p>
        <p>最終解答を「{props.answer}」で送信しますか？</p>
        <Buttons>
          <Button
            size={'large'}
            color={'negative'}
            onClick={() => props.onCancel()}
          >
            戻る
          </Button>
          <Button
            size={'large'}
            color={'positive'}
            onClick={() => props.onSubmit()}
          >
            送信する
          </Button>
        </Buttons>
      </Text>
    </Stage2LastConfirmWrapper>
  );
};

const Text = styled(TextWindow)`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
`;

const Stage2LastConfirmWrapper = styled.div`
  position: fixed;
  background-color: ${Colors.gray8_op090};
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 11;
`;

const Buttons = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  transform: translateX(-50%);
  padding: 2rem 0;
  left: 50%;

  button {
    margin-right: 4rem;
  }

  button:last-child {
    margin-right: 0;
  }
`;

export default Stage2LastConfirm;
